<template>
    <v-container fluid>
        <div class="text-h6 grey--text text--darken-2">
            {{ $t('userPay.title') }}
        </div>
        <v-card outlined>
            <div class="py-3 px-4">
                <v-row align="center">
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="queryCondition.exchangeCode" :label="$t('userPay.header.exchange_code')"
                            hide-details outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-select v-model="queryCondition.homeworkId" :items="homeworkList"
                            :label="$t('userPay.header.homework_name')" hide-details dense outlined></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-select v-model="queryCondition.status" :items="ucphStatusList"
                            :label="$t('userPay.header.status')" hide-details dense outlined></v-select>
                    </v-col>
                    <v-col cols="12" class="text-right pt-0">
                        <v-btn depressed outlined class="mr-1 normal-case" @click="clearSearch">{{ $t('button.clear') }}</v-btn>
                        <v-btn depressed color="primary" class="normal-case" @click="searchByCode">{{ $t('button.search') }}</v-btn>
                    </v-col>
                </v-row>
            </div>
            <div class="mx-4">
                <v-divider></v-divider>
            </div>
            <div class="px-4 mt-2 ">
                <!-- <span class="mr-2"><v-icon class="plan-status-1" x-small> mdi-circle-outline </v-icon> {{
                    $t("userPay.status.1") }}</span> -->
                <span class="mr-2"><v-icon class="plan-status-2" x-small> mdi-circle </v-icon> {{ $t("userPay.status.2")
                }}</span>
                <span class="mr-2"><v-icon class="plan-status-3" x-small> mdi-circle </v-icon> {{ $t("userPay.status.3")
                }}</span>
                <span class="mr-2"><v-icon class="plan-status--1" x-small> mdi-circle </v-icon> {{ $t("userPay.status.-1")
                }}</span>
                <span class="mr-2"><v-icon x-small> mdi-circle-outline </v-icon> {{ $t("userPay.status.4")
                }}</span>
            </div>
            <v-subheader>{{ formatRecord() }}<span class="ml-2">({{ queryCondition.homeworkId ? $t('userPay.unuse') : $t('userPay.unbound')}}: {{ this.unuseCount}})</span></v-subheader>
            <v-data-table :headers="headers" :items="dataList" :server-items-length="totalRecord" :loading="loading"
                :page.sync="currentPage" :options.sync="queryOptions" :items-per-page="10" hide-default-footer disable-sort
                class="mx-6 mt-2">
                <template v-slot:item.status="{ item }">
                    <v-icon v-if="item.status != 1" :class="`plan-status-${item.status}`" x-small>
                        mdi-circle </v-icon>
                    <v-icon v-else :class="`plan-status-${item.status}`" x-small>
                        mdi-circle-outline </v-icon>
                </template>

                <template v-slot:item.ownDate="{ item }">
                    <span>{{ item.ownerName }}</span>
                    <br />
                    <span>{{ item.ownDate }}</span>
                </template>
                <template v-slot:item.userName="{ item }">
                    <div v-if="item.userName != null">
                        <span>{{ item.userName }}</span>
                        <br />
                        <span>{{ item.useExpireDate }}</span>
                    </div>
                    <span v-else class="red--text">
                        {{ item.exchangeExpireDate }}
                    </span>
                </template>
                <!-- <template v-slot:item.soldDate="{ item }">
                    <span v-if="item.soldDate != null">
                        {{ item.soldDate }}
                    </span>
                    <span v-else class="red--text">
                        {{ item.sellExpireDate }}
                    </span>

                </template> -->
                <template v-slot:item.homeworkName="{ item }">
                    <div v-if="item.homeworkName != null">
                        {{ item.homeworkName }}
                    </div>
                    <span v-else>{{ $t('userPay.unset') }}</span>
                </template>

                <template v-slot:item.commentList="{ item }">
                    <div class="text-decoration-underline my-1" v-for="(comment, commentInd) in item.commentList"
                        :key="commentInd">
                        {{ comment }}
                    </div>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn small class="mr-2 mt-1 normal-case" outlined @click="viewPlanDetail(item.id)">
                        <v-icon small class="mr-1"> mdi-eye </v-icon>
                        {{ $t('button.detail') }}
                    </v-btn>
                </template>
            </v-data-table>
            <div class="text-center my-4">
                <v-pagination v-model="currentPage" :length="pageCount" :total-visible="11" circle color="orange accent-2">
                </v-pagination>
            </div>
        </v-card>
        <v-dialog v-model="openPlanDetailDialog" width="350">
            <v-card>
                <v-card-title>
                    {{ $t("userPay.detail.title") }}
                </v-card-title>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item v-show="planDetail.status == -1">
                        <v-list-item-content>
                            <v-list-item-title><span class="red--text">({{ $t("userPay.status.-1")
                            }})</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t("userPay.header.own_date") }}</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ planDetail.ownDate }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t("userPay.header.own_info") }}</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ planDetail.ownerName }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t("userPay.header.homework_name") }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <template v-if="planDetail.homeworkName != null">
                                    {{ planDetail.homeworkName }}
                                </template>
                                <template v-else>
                                    {{ $t('userPay.unset') }}
                                </template>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t("userPay.header.sold_date") }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <template v-if="planDetail.soldDate != null">
                                    {{ planDetail.soldDate }}
                                </template>
                                <template v-else>
                                    {{ $t('userPay.unsold') }}
                                </template>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item> -->
                    <v-list-item v-show="planDetail.soldDate != null || planDetail.ownDate != null">
                        <v-list-item-content>
                        <v-list-item-title>{{ $t("userPay.header.exchange_code") }}</v-list-item-title>
                        <v-list-item-subtitle>
                            <template v-if="planDetail.exchangeCode">
                                {{ planDetail.exchangeCode }}
                            </template>
                            <template v-else-if="planDetail.status != -1 && planDetail.soldDate != null && !planDetail.userName">
                                <v-btn x-small color="primary normal-case" @click="setCode(planDetail.id)">{{ $t('userPay.button.create_code') }}</v-btn>
                            </template>
                            <template v-else>
                                {{ $t('userPay.unset') }}
                            </template>
                        </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t("userPay.header.exchange_expire_date") }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <template v-if="planDetail.exchangeExpireDate != null">
                                    {{ planDetail.exchangeExpireDate }}
                                </template>
                                <template v-else>
                                    {{ $t('userPay.unset') }}
                                </template>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t("userPay.header.use_date") }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <template v-if="planDetail.useDate != null">
                                    {{ planDetail.useDate }}
                                </template>
                                <template v-else>
                                    {{ $t('userPay.unuse') }}
                                </template>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-show="planDetail.useDate != null">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t("userPay.header.user_name") }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <template v-if="planDetail.useDate != null">
                                    {{ planDetail.userName }}
                                </template>
                                <template v-else>
                                    {{ $t('userPay.unuse') }}
                                </template>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-show="planDetail.useExpireDate != null">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t("userPay.header.use_expire_date") }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <template v-if="planDetail.useExpireDate != null">
                                    {{ planDetail.useExpireDate }}
                                </template>
                                <template v-else>
                                    {{ $t('userPay.unuse') }}
                                </template>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t("userPay.header.comment") }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <div class="text-decoration-underline my-1"
                                    v-for="(comment, commentInd) in planDetail.commentList" :key="commentInd">
                                    {{ comment }}
                                </div>
                                <v-btn small color="primary" class="normal-case" @click="openCommentDialog(planDetail.id)">{{ $t("button.new")
                                }}</v-btn>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-show="planDetail.status != -1 && planDetail.soldDate != null && planDetail.userName == null">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t("userPay.header.release") }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <v-btn small color="error" class="normal-case"
                                    v-show="planDetail.homeworkName != null"
                                    :loading="loading" @click="removeHomeworkDialog = true">
                                    {{ $t('userPay.button.release') }}
                                </v-btn>
                                <v-btn small color="error" class="normal-case"
                                    v-show="planDetail.homeworkName == null"
                                    :loading="loading" @click="release(planDetail.id, false)">
                                    {{ $t('userPay.button.release') }}
                                </v-btn>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                </v-list>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="normal-case" outlined @click="openPlanDetailDialog = false">
                        {{ $t('button.close') }}
                    </v-btn>

                    <v-btn color="primary" class="normal-case"
                        v-show="planDetail.status != -1 && planDetail.homeworkName != null && planDetail.soldDate == null && planDetail.userName == null"
                        :loading="loading" @click="sold(planDetail.id)">
                        {{ $t('userPay.button.sold') }}
                    </v-btn>

                    <v-btn color="primary" class="normal-case"
                        v-show="planDetail.status == -1 && ucpUnuseCount > 0"
                        :loading="loading" @click="renewDialog = true">
                        {{ $t('userPay.button.renew') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="removeHomeworkDialog" max-width="480">
            <v-card>
                <v-card-title>
                    {{ $t('userPay.msg.remove_homework') }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="removeHomeworkDialog = false" class="normal-case">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="error" @click="release(planDetail.id, false)" class="normal-case">
                        {{ $t('userPay.button.not_remove_homework') }}
                    </v-btn>
                    <v-btn color="error" @click="release(planDetail.id, true)" class="normal-case">
                        {{ $t('userPay.button.remove_homework') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="addCommentDialog" max-width="300">
            <v-card>
                <v-card-title>
                    {{ $t('userPay.header.add_comment')}}
                </v-card-title>
                <v-form class="px-5" ref="commentForm" v-model="commentForm.valid" lazy-validation>
                    <div class="mb-5">
                        <label class="font-weight-medium">{{ $t('userPay.header.comment') }}</label>
                        <v-text-field v-model="commentForm.comment" :label="$t('userPay.header.comment')" outlined flat solo
                            dense hide-details="auto" :rules="commentForm.commentRules"></v-text-field>
                    </div>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="addCommentDialog = false" class="normal-case">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn @click="addComment(planDetail.id)" class="normal-case">
                        {{ $t('button.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="renewDialog" max-width="400">
            <v-card>
                <v-card-title>
                    {{ $t('userPay.msg.renew_plan') }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="renewDialog = false" class="normal-case">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="error" @click="renew(planDetail.id)" class="normal-case">
                        {{ $t('button.confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import { userPayServices } from '@/services/userPay.js';
import { diaryServices } from '@/services/diary.js';

export default {
    name: 'student',
    data() {
        return {
            openPlanDetailDialog: false,
            removeHomeworkDialog: false,
            headers: [
                {
                    text: this.$t('userPay.header.status'),
                    value: 'status',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('userPay.header.own_info'),
                    value: 'ownDate',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('userPay.header.homework_name'),
                    value: 'homeworkName',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('userPay.header.plan_days'),
                    value: 'planDays',
                    align: 'left',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                // {
                //     text: this.$t('userPay.header.sold_status'),
                //     value: 'soldDate',
                //     align: 'center',
                //     class: 'grey lighten-4',
                //     filterable: false,
                //     sortable: false,
                // },
                {
                    text: this.$t('userPay.header.user_name'),
                    value: 'userName',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('userPay.header.comment'),
                    value: 'commentList',
                    align: 'left',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                // {
                //     text: this.$t('userPay.header.use_expire_date'),
                //     value: 'useExpireDate',
                //     align: 'left',
                //     class: 'grey lighten-4',
                //     filterable: false,
                //     sortable: false,
                // },
                // {
                //     text: this.$t('userPay.header.exchange_code'),
                //     value: 'exchangeCode',
                //     align: 'left',
                //     class: 'grey lighten-4',
                //     filterable: false,
                //     sortable: false,
                // },
                {
                    text: '',
                    value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                },],
            dataList: [],
            planDetail: {
                id: null,
                soldDate: null,
                ownDate: null,
                useDate: null,
                userName: null,
                homeworkName: null,
                exchangeExpireDate: null,
                planDays: null,
                useExpireDate: null,
                exchangeCode: null,
            },
            currentPage: 1,
            pageCount: 1,
            totalRecord: 0,
            ucpUnuseCount: 0,
            unuseCount: 0,
            loading: false,
            queryOptions: {
                page: 1,

            },
            queryCondition: {
                exchangeCode: '',
                homeworkId: '',
                status: '',
            },
            homeworkList: [],
            // ucphStatusList: [
            //     /*{
            //         text: this.$t('userPay.status.1'),
            //         value: `1`,
            //     },*/
            //      {
            //         text: this.$t('userPay.status.2'),
            //         value: `2`,
            //     }, {
            //         text: this.$t('userPay.status.3'),
            //         value: `3`,
            //     }, {
            //         text: this.$t('userPay.status.-1'),
            //         value: `-1`,
            //     },{
            //         text: this.$t('userPay.status.4'),
            //         value: `4`,
            //     },
            // ],
            addCommentDialog: false,
            commentForm: {
                valid: false,
                id: null,
                comment: null,
                commentRules: (v) => !!v || this.$t('general.msg.must_filled'),
            },
            renewDialog: false,
        };
    },
    computed: {
        ucphStatusList() {
            return this.queryCondition.homeworkId ? [
                {
                    text: this.$t('userPay.status.2'),
                    value: `2`,
                }, {
                    text: this.$t('userPay.status.3'),
                    value: `3`,
                }, {
                    text: this.$t('userPay.status.-1'),
                    value: `-1`,
                }
            ] : [
                {
                    text: this.$t('userPay.status.2'),
                    value: `2`,
                }, {
                    text: this.$t('userPay.status.3'),
                    value: `3`,
                }, {
                    text: this.$t('userPay.status.-1'),
                    value: `-1`,
                },{
                    text: this.$t('userPay.status.4'),
                    value: `4`,
                },
            ];
        },
    },
    watch: {
        queryOptions: {
            handler() {
                this.getDataList()
            },
            deep: true,
        },
    },
    async created() {
        await this.getHomeworkList();
        ///Get basic info
        // await this.getDataList();
    },
    methods: {
        async getHomeworkList() {
            const res = await diaryServices.getMyDiaryHomeworkList();
            res.data.forEach(dh => {
                this.homeworkList.push({
                    text: dh.name,
                    value: dh.id,
                });
            });
        },
        searchByCode() {
            this.queryOptions.page = 1;
            if (this.queryCondition.homeworkId && this.queryCondition.status == '4') {
                this.queryCondition.status = '';
            }
            this.getDataList();
        },
        clearSearch() {
            this.queryOptions.page = 1;
            this.queryCondition.exchangeCode = '';
            this.queryCondition.homeworkId = '';
            this.queryCondition.status = '';
            this.getDataList();
        },
        async getDataList() {
            this.loading = true;

            const { page } = this.queryOptions;
            const payload = {
                page: page,
                q_code: this.queryCondition.exchangeCode,
                q_homeworkId: this.queryCondition.homeworkId,
                q_status: this.queryCondition.status,
            }

            const res = await userPayServices.getMyOwnUserChargePlanList(payload);
            let items = [];
            console.log('res:', res)
            const now = new Date();
            res.list.forEach(item => {
                let status = -1;
                if (item.use_expire_date != null && new Date(item.use_expire_date) < now) {
                    status = -1;
                } else if (item.exchange_expire_date != null && item.use_date == null && new Date(item.exchange_expire_date) < now) {
                    status = -1;
                } else if (item.sold_date == null && item.use_date == null) {
                    status = 1;
                } else if (item.use_date == null) {
                    status = 2;
                } else if (item.use_date != null && new Date(item.use_expire_date) > now) {
                    status = 3;
                }
                items.push({
                    id: item.id,
                    status: status,
                    soldDate: item.sold_date,
                    ownerName: item.owner_name,
                    ownDate: item.create_date,
                    useDate: item.use_date,
                    userName: item.user_name,
                    homeworkName: item.homework_name,
                    exchangeExpireDate: item.exchange_expire_date,
                    planDays: item.plan_days,
                    useExpireDate: item.use_expire_date,
                    sellExpireDate: item.sell_expire_date,
                    exchangeCode: item.exchange_code,
                    commentList: item.comments
                });
            });
            this.dataList = items;
            this.totalRecord = res.total;
            this.pageCount = res.totalPage;
            this.currentPage = res.page;

            if (res.page == 1) {
                this.ucpUnuseCount = await userPayServices.getUnsoldUserChargePlanCount(undefined);
                this.unuseCount = await userPayServices.getUnsoldUserChargePlanCount(this.queryCondition.homeworkId);
            }
            this.loading = false;
            console.log('currentPage:', this.currentPage)
        },
        viewPlanDetail(id) {
            this.planDetail = this.dataList.find(plan => plan.id == id);
            this.openPlanDetailDialog = true;
        },
        async sold(id) {
            this.loading = true;
            const result = await userPayServices.sold(id);
            this.loading = false;
            if (result.success) {
                await this.getDataList();
                this.viewPlanDetail(id);
            }
        },
        async release(id, removeHomework) {
            this.loading = true;
            const result = await userPayServices.release(id, { remove_homework: removeHomework ? 'true' : 'false' });
            this.loading = false;
            if (result.success) {
                this.removeHomeworkDialog = false;
                await this.getDataList();
                this.viewPlanDetail(id);
            }
        },

        async addComment(id) {
            if (this.$refs.commentForm.validate()) {
                this.loading = true;
                const result = await userPayServices.addComment(id, { comment: this.commentForm.comment });
                this.loading = false;
                if (result.success) {
                    this.addCommentDialog = false;
                    await this.getDataList();
                    this.viewPlanDetail(id);
                }
            }

        },

        async renew(id) {
            this.loading = true;
            const res = await userPayServices.renew(id);
            const result = res.data;
            this.loading = false;
            if (result.return_code) {
                alert('沒有剩餘的方案');
                this.renewDialog = false;
            } else {
                this.renewDialog = false;
                await this.getDataList();
                this.viewPlanDetail(result.id);
            }
        },
        openCommentDialog(id) {
            this.addCommentDialog = true;
            this.commentForm.id = id;
        },
        async setCode(id) {
            this.loading = true;
            const result = await userPayServices.setNewCode(id);
            this.loading = false;
            if (result.success) {
                this.removeHomeworkDialog = false;
                await this.getDataList();
                this.viewPlanDetail(id);
            }
        },
        formatRecord() {
            return this.$t('general.msg.total_record').replace('${num}', this.totalRecord);
        }
    },
};
</script>

<style scoped>

.plan-status-2 {
    color: #FFC107;
}

.plan-status-3 {
    color: #8BC34A;
}

.plan-status--1 {
    color: #9E9E9E;
}

::v-deep tbody tr:nth-of-type(even) {
    background-color: #f5f5f5;
}
</style>
